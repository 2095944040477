/* You can add global styles to this file, and also import other style files */

$noart-yellow:#FFCC4D;
$accordion-button-active-bg: lighten($noart-yellow, 5%);

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

.btn-secondary {
    @include button-variant($noart-yellow, darken($noart-yellow, 10%));
}

.btn-outline-secondary {
    @include button-outline-variant(darken($noart-yellow, 30%), color-contrast($noart-yellow), lighten($noart-yellow, 10%), $noart-yellow);
}

body {
    background-color: rgb(248, 249, 250);
}
